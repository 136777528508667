@media print {
  #app-root {
    display: block !important;
  }
  #chart-wrapper {
    overflow: visible !important;
  }
  #no-print, #scroll-table {
    display: none;
  }
  #split-table {
    display: block !important;
  }
  #content-wrapper {
    box-shadow: none;
  }
  #report .page-break {
    page-break-before: always; 
    page-break-inside: avoid;
  }
  #report table {
    page-break-inside: avoid;
  }
}